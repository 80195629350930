import React, { useEffect, useState } from "react";
import { db } from "../../firebaseConfig";
import { collection, addDoc, getDocs, deleteDoc, query, where, doc, orderBy } from "firebase/firestore";

import Navbar from "../../components/Navbar";
import { LocationOn, Schedule, Description } from "@mui/icons-material";
import DatePicker from "react-datepicker"; // Date Picker
import "react-datepicker/dist/react-datepicker.css"; // Date Picker styles
import { toast, ToastContainer } from "react-toastify"; // Toast notifications
import "react-toastify/dist/ReactToastify.css"; // Toastify styles
import { useAuth } from "../../contexts/authContext"; // Assuming you have an auth context for current user

const CreateCabShare = () => {
  const [formData, setFormData] = useState({
    fromLocation: "",
    toLocation: "",
    description: "",
  });
  const [dateTime, setDateTime] = useState(new Date());
  const [error, setError] = useState(null);
  const [myPosts, setMyPosts] = useState([]); // State for user's posts
  const [loading, setLoading] = useState(true); // Loading state for posts
  const { currentUser } = useAuth(); // Get the current logged-in user
 

  // Fetch user's posts
  useEffect(() => {
    const fetchUserPosts = async () => {
      if (currentUser) {
        const userPostsQuery = query(
          collection(db, "cabShares"),
          where("userId", "==", currentUser.uid),
          orderBy("createdAt", "desc")
        );
        const querySnapshot = await getDocs(userPostsQuery);
        setMyPosts(querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
        setLoading(false);
      }
    };
    fetchUserPosts();
  }, [currentUser]);

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle cab share post creation
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    const { fromLocation, toLocation, description } = formData;

    if (!fromLocation || !toLocation || !dateTime) {
      setError("Please fill in all the required fields");
      return;
    }

    try {
      await addDoc(collection(db, "cabShares"), {
        userId: currentUser.uid, // Store the user ID
        fromLocation,
        toLocation,
        dateTime: dateTime.toISOString(), // Store ISO string for date-time
        description,
        createdAt: new Date(),
      });

      toast.success("Cab share created successfully!");
      
      // Clear form after successful submission
      setFormData({
        fromLocation: "",
        toLocation: "",
        description: "",
      });
      setDateTime(new Date());

      // Fetch user's updated posts
      const userPostsQuery = query(
        collection(db, "cabShares"),
        where("userId", "==", currentUser.uid),
        orderBy("createdAt", "desc")
      );
      const querySnapshot = await getDocs(userPostsQuery);
      setMyPosts(querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    } catch (error) {
      console.error("Error adding document: ", error);
      setError("Failed to create a cab share. Please try again.");
    }
  };

  // Handle post deletion
  const handleDelete = async (id) => {
    try {
      await deleteDoc(doc(db, "cabShares", id));
      toast.success("Post deleted successfully!");
      setMyPosts(myPosts.filter((post) => post.id !== id)); // Remove deleted post from the state
    } catch (error) {
      console.error("Error deleting document: ", error);
      toast.error("Failed to delete post. Please try again.");
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-black via-slate-800 to-slate-900 text-white flex flex-col">
      {/* Navbar */}
      <Navbar />

      <div className="flex-1 flex flex-col items-center justify-center p-8">
        <h1 className="text-4xl font-extrabold mb-8">Create Cab Share</h1>

        {error && <p className="text-red-500 mb-4">{error}</p>}

        {/* Form to create a new cab share */}
        <form onSubmit={handleSubmit} className="bg-slate-800 p-8 rounded-lg shadow-lg w-full max-w-lg space-y-6">
          {/* From Location */}
          <div className="flex items-center space-x-2">
            <LocationOn className="text-slate-400" />
            <input
              type="text"
              name="fromLocation"
              value={formData.fromLocation}
              onChange={handleInputChange}
              placeholder="Enter starting location"
              className="bg-slate-700 text-white px-4 py-2 rounded-lg flex-1 focus:outline-none"
              required
            />
          </div>

          {/* To Location */}
          <div className="flex items-center space-x-2">
            <LocationOn className="text-slate-400" />
            <input
              type="text"
              name="toLocation"
              value={formData.toLocation}
              onChange={handleInputChange}
              placeholder="Enter destination"
              className="bg-slate-700 text-white px-4 py-2 rounded-lg flex-1 focus:outline-none"
              required
            />
          </div>

          {/* Date and Time */}
          <div className="flex items-center space-x-2">
            <Schedule className="text-slate-400" />
            <DatePicker
              selected={dateTime}
              onChange={(date) => setDateTime(date)}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              dateFormat="MMMM d, yyyy h:mm aa"
              placeholderText="Select date and time"
              className="bg-slate-700 text-white px-4 py-2 rounded-lg w-full focus:outline-none"
              required
            />
          </div>

          {/* Description */}
          <div className="flex items-center space-x-2">
            <Description className="text-slate-400" />
            <textarea
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              placeholder="Any additional details (optional)"
              className="bg-slate-700 text-white px-4 py-2 rounded-lg flex-1 focus:outline-none"
            />
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            className="w-full bg-blue-500 text-white px-4 py-2 rounded-lg text-lg font-semibold hover:bg-blue-600 transition-transform transform hover:scale-105"
          >
            Create Cab Share
          </button>
        </form>

        {/* User's created posts */}
        <div className="mt-10 w-full max-w-lg">
          <h2 className="text-2xl font-bold mb-4">My Created Cab Shares</h2>
          {loading ? (
            <p>Loading your posts...</p>
          ) : myPosts.length === 0 ? (
            <p>No cab shares created by you.</p>
          ) : (
            <div className="space-y-4">
              {myPosts.map((post) => (
                <div key={post.id} className="bg-slate-700 p-4 rounded-lg shadow-md">
                  <p className="text-lg font-bold">
                    From: {post.fromLocation} - To: {post.toLocation}
                  </p>
                  <p>Date: {new Date(post.dateTime).toLocaleString()}</p>
                  {post.description && <p>Description: {post.description}</p>}
                  <button
                    onClick={() => handleDelete(post.id)}
                    className="mt-2 bg-red-500 text-white px-4 py-2 rounded-lg"
                  >
                    Delete
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>

        {/* Toast container to display notifications */}
        <ToastContainer />
      </div>
    </div>
  );
};

export default CreateCabShare;
