import React, { useState } from 'react';
import ClubPostForm from '../posts/ClubPostsForm'; // Import the ClubPostForm component

const AdminDashboard = () => {
  const [task, setTask] = useState('');

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-700 via-gray-800 to-black text-white flex flex-col items-center">
      <h2 className="text-3xl mt-8">Admin Dashboard</h2>

      <div className="mt-8">
        <select
          value={task}
          onChange={(e) => setTask(e.target.value)}
          className="w-full p-3 bg-gray-700 rounded text-white"
        >
          <option value="">Select Task</option>
          <option value="uploadPDF">Upload PDFs</option>
          <option value="addClubPost">Add Club Post</option>
        </select>
      </div>

      {task === 'uploadPDF' && (
        <form className="mt-8 space-y-4 w-full max-w-md">
          {/* Your PDF upload form code here */}
        </form>
      )}

      {task === 'addClubPost' && <ClubPostForm />} {/* Render ClubPostForm component */}
    </div>
  );
};

export default AdminDashboard;
