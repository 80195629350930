import React, { useState, useEffect, useRef } from "react";
import { db } from "../firebaseConfig";
import { collection, addDoc, query, orderBy, onSnapshot,  } from "firebase/firestore";
import { useParams } from "react-router-dom";
import { auth } from "../firebaseConfig"; // Firebase authentication
import { format } from "date-fns"; // Format timestamp
import Navbar from "../components/Navbar";

const ChatPage = () => {
  const { cabShareId } = useParams(); // Get cab share ID from URL params
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [userId, setUserId] = useState(null); // Holds the authenticated user's ID
  const [userLabels, setUserLabels] = useState({}); // Store unique labels for Travellers
  const [travellerCount, setTravellerCount] = useState(1); // Counter for Traveller labels

  const chatEndRef = useRef(null); // For scrolling to the end of the chat

  // Fetch current user ID from Firebase Auth
  useEffect(() => {
    const currentUser = auth.currentUser;
    if (currentUser) {
      setUserId(currentUser.uid); // Set the authenticated user's ID
    }
  }, []);

  // Fetch chat messages in real-time
  useEffect(() => {
    const chatQuery = query(
      collection(db, `chats/${cabShareId}/messages`),
      orderBy("timestamp", "asc")
    );

    const unsubscribe = onSnapshot(chatQuery, (snapshot) => {
      const fetchedMessages = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

      // Assign unique labels to each sender
      fetchedMessages.forEach((message) => {
        if (!userLabels[message.sender] && message.sender !== userId) {
          setUserLabels((prevLabels) => ({
            ...prevLabels,
            [message.sender]: `Traveller${travellerCount}`,
          }));
          setTravellerCount((prevCount) => prevCount + 1);
        }
      });

      setMessages(fetchedMessages);
      chatEndRef.current?.scrollIntoView({ behavior: "smooth" }); // Scroll to the end of the chat
    });

    return () => unsubscribe();
  }, [cabShareId, userId, userLabels, travellerCount]);

  // Send a new message
  const sendMessage = async () => {
    if (newMessage.trim() === "" || !userId) return;

    await addDoc(collection(db, `chats/${cabShareId}/messages`), {
      text: newMessage,
      sender: userId,
      timestamp: new Date(),
    });

    setNewMessage("");
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" }); // Scroll to the latest message
  };

  // Format the timestamp
  const formatDate = (timestamp) => {
    if (!timestamp) return "";
    return format(new Date(timestamp.seconds * 1000), "p, MMM d");
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-black via-gray-800 to-gray-900 text-white flex flex-col">
      {/* Navbar */}
      <Navbar />

      {/* Chat Section */}
      <div className="flex-1 overflow-y-auto px-6 py-4 mt-16 bg-gray-900 relative">
        {messages.length === 0 ? (
          <p className="text-gray-400 text-center mt-10">No messages yet. Start the conversation!</p>
        ) : (
          messages.map((message) => (
            <div
              key={message.id}
              className={`mb-4 p-4 max-w-sm md:max-w-md rounded-lg shadow-lg ${
                message.sender === userId
                  ? "ml-auto bg-blue-600 text-white"
                  : "mr-auto bg-gray-700 text-white"
              }`}
            >
              <p>{message.text}</p>
              <span className="block text-xs text-gray-400 mt-2">
                {message.sender === userId
                  ? "You"
                  : userLabels[message.sender] || `Traveller${travellerCount}`} - {formatDate(message.timestamp)}
              </span>
            </div>
          ))
        )}
        <div ref={chatEndRef} />
      </div>

      {/* Input for sending new message */}
      <div className="sticky bottom-0 bg-gray-800 p-4 border-t border-gray-700 flex items-center space-x-4">
        <input
          type="text"
          placeholder="Type your message..."
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          className="flex-1 bg-gray-700 text-white px-4 py-2 rounded-lg focus:outline-none"
        />
        <button
          onClick={sendMessage}
          className="bg-green-600 text-white px-4 py-2 rounded-lg hover:bg-green-700 transition"
        >
          Send
        </button>
      </div>
    </div>
  );
};

export default ChatPage;
