import React, { useState } from 'react';
import { signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { auth } from '../../firebaseConfig';
import { Link, useNavigate } from 'react-router-dom';
import { FaGoogle, FaEye, FaEyeSlash } from 'react-icons/fa';
import { gsap } from 'gsap';
import BookCanvas from '../../components/BookCanvas';
import  BorderBeam  from '../../components/borderBeam';  // Import the BorderBeam component

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const provider = new GoogleAuthProvider();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      const displayName = user.displayName;
      console.log("Logged in as:", displayName);
      navigate('/');
    } catch (err) {
      setError(err.message);
    }
  };

  const handleGoogleLogin = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      const displayName = user.displayName;
      console.log("Logged in as:", displayName);
      navigate('/');
    } catch (err) {
      setError(err.message);
    }
  };

  // Adding animation on component mount using GSAP
  React.useEffect(() => {
    gsap.fromTo(
      ".login-container",
      { opacity: 0, x: 50 },
      { opacity: 1, x: 0, duration: 1, ease: "power3.out" }
    );
  }, []);

  return (
    <div className="min-h-screen flex flex-col bg-slate-950">
      <div className="flex flex-1 flex-col md:flex-row">
        {/* Left section with 3D model */}
        <div className="flex-1 flex items-center justify-center bg-slate-950 p-10 md:p-6">
          <div className="w-full h-full flex items-center justify-center">
            {/* Adjust the BookCanvas to take up more space */}
            <div className="w-full h-full">
              <BookCanvas />
            </div>
          </div>
        </div>

        {/* Right section with login form */}
        <div className="flex-1 flex items-center justify-center p-4 relative">
          {/* Add the BorderBeam here */}
          

          <div className="login-container bg-gray-900 p-6 md:p-8 rounded-lg shadow-lg w-full max-w-md transition-all duration-300 relative z-10">
          <BorderBeam className="relative w-full h-full rounded-lg" size={200} borderWidth={2} />

            <h2 className="text-3xl md:text-4xl text-white mb-6">Login</h2>
            <form onSubmit={handleLogin}>
              <input 
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full p-3 mb-4 border border-gray-700 rounded bg-gray-800 text-white placeholder-gray-400 focus:ring-2 focus:ring-blue-500 transition-all"
              />
              <div className="relative mb-6">
                <input 
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full p-3 border border-gray-700 rounded bg-gray-800 text-white placeholder-gray-400 focus:ring-2 focus:ring-blue-500 transition-all"
                />
                <button 
                  type="button" 
                  className="absolute right-3 top-3 text-gray-400" 
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </button>
              </div>
              {error && <p className="text-red-400 mb-4">{error}</p>}
              <button 
                type="submit" 
                className="w-full bg-blue-600 text-white p-3 rounded-lg hover:bg-blue-700 transition-transform transform hover:scale-105"
              >
                Login
              </button>
            </form>

            <div className="mt-6 flex flex-col items-center">
              <p className="text-white mb-4">
                Don't have an account? <Link to="/register" className="text-blue-400 hover:underline">Register</Link>
              </p>
              <p className="text-white mb-4">
                <Link to="/forgot-password" className="text-blue-400 hover:underline">Forgot Password?</Link>
              </p>
              <button 
                onClick={handleGoogleLogin} 
                className="flex items-center bg-blue-500 text-white p-3 rounded-lg shadow-lg hover:bg-blue-600 transition-transform transform hover:scale-105 mb-4"
              >
                <FaGoogle className="mr-2" /> Login with Google
              </button>
              <Link to="/admin-login" className="text-sm text-blue-400 hover:underline">
                Admin Login
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
