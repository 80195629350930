import React, { useEffect, useState } from "react";
import { db } from "../../firebaseConfig"; // Firestore config
import { collection, getDocs, query, orderBy } from "firebase/firestore"; // Firestore functions

import { useNavigate } from "react-router-dom"; // Import useNavigate
import { useAuth } from "../../contexts/authContext"; // Assuming you're using an auth context for logged-in user
import { FiShoppingBag } from "react-icons/fi"; // Example icon from react-icons

const BuySellPosts = () => {
  const [posts, setPosts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const { currentUser } = useAuth(); // Get current logged-in user
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    const fetchPosts = async () => {
      const postsQuery = query(collection(db, "buySellPosts"), orderBy("createdAt", "desc"));
      const querySnapshot = await getDocs(postsQuery);
      setPosts(querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
      setLoading(false);
    };
    fetchPosts();
  }, []);

  const filteredPosts = posts.filter(
    (post) => searchQuery === "" || post.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Function to handle chat initiation
  const handleChat = (post) => {
    if (!currentUser) {
      alert("Please log in to start a chat.");
      return;
    }

    // Navigate to buy/sell chat page with post ID and creator ID
    navigate(`/chat/buy-sell/${post.id}`, { 
      state: { 
        postId: post.id, 
        creatorId: post.creatorId 
      } 
    }); 
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-black via-slate-800 to-slate-900 text-white flex flex-col">
      
      <div className="flex-1 p-6">
        <h1 className="text-3xl font-bold mb-4">Buy & Sell Posts</h1>
        <input
          type="text"
          placeholder="Search by title"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="bg-gray-200 text-black px-4 py-2 rounded-lg mb-4 w-full"
        />

        {loading ? (
          <p>Loading posts...</p>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            {filteredPosts.length === 0 ? (
              <p>No posts available</p>
            ) : (
              filteredPosts.map((post) => (
                <div key={post.id} className="bg-white p-3 rounded-lg shadow-sm hover:shadow-lg transform transition duration-300">
                  {/* Highlighted Title */}
                  <h3 className="text-md font-bold text-blue-600 mb-2">{post.title}</h3>
                  
                  {/* Image */}
                  <img
                    src={post.imageUrl}
                    alt={post.title}
                    className="w-full h-40 object-cover mb-2 rounded-lg"
                  />
                  
                  {/* Shortened Description */}
                  <p className="text-gray-700 text-sm mb-2">
                    {post.description.length > 80 ? `${post.description.substring(0, 80)}...` : post.description}
                  </p>
                  
                  {/* Highlighted Price */}
                  <p className="text-lg font-semibold text-blue-900">
                    ₹{post.price}
                  </p>

                  {/* Chat Button */}
                  <button
                    onClick={() => handleChat(post)}
                    className="w-full bg-green-500 text-slate-900 px-3 py-1 rounded-lg mt-2 hover:bg-green-600 transition-transform transform hover:scale-105"
                  >
                    Chat with Seller
                  </button>
                </div>
              ))
            )}
          </div>
        )}
      </div>

      {/* Floating Action Button with Animation */}
      <button
        onClick={() => navigate("/create-buy-sell")} // Redirect to Create Buy & Sell
        className="fixed bottom-10 right-10 bg-blue-500 text-white rounded-full p-4 shadow-lg hover:bg-blue-600 transition-transform transform hover:scale-105 flex items-center space-x-2"
      >
        <FiShoppingBag className="animate-bounce" size={24} />
        <span className="hidden sm:inline">Post an Item</span>
      </button>
    </div>
  );
};

export default BuySellPosts;
