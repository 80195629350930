import React, { useState, useEffect } from 'react';
import { collection, getDocs, updateDoc, doc } from 'firebase/firestore'; // Firestore methods
import { firestore } from '../../firebaseConfig'; // Firestore already set up

const Leader = () => {
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [loadingLeaderboard, setLoadingLeaderboard] = useState(true);

  // Function to fetch user time spent and calculate karma
  const fetchLeaderboardData = async () => {
    setLoadingLeaderboard(true);
    try {
      const leaderboardRef = collection(firestore, 'userTimeSpent');
      const snapshot = await getDocs(leaderboardRef);

      const userKarma = {};
      snapshot.forEach((doc) => {
        const data = doc.data();
        const username = data.username || 'Anonymous'; // Fetch username
        const timeSpent = data.timeSpent;

        // Calculate karma as timeSpent in seconds / 60 to get minutes as a simple example
        if (!userKarma[username]) {
          userKarma[username] = { karma: 0, id: doc.id }; // Store user ID along with karma
        }
        userKarma[username].karma += timeSpent / 60; // Convert seconds to minutes for karma
      });

      // Convert userKarma object to an array and sort by karma (descending)
      const sortedLeaderboard = Object.keys(userKarma)
        .map((username) => ({
          username, // Use the username in the leaderboard
          karma: userKarma[username].karma,
          id: userKarma[username].id, // Store user ID for Firestore update
        }))
        .sort((a, b) => b.karma - a.karma); // Sort by karma, descending

      // Update ranks in Firestore
      await Promise.all(sortedLeaderboard.map((userData, index) => {
        const rank = index + 1; // Calculate rank
        return updateDoc(doc(firestore, 'userTimeSpent', userData.id), { rank });
      }));

      setLeaderboardData(sortedLeaderboard);
    } catch (error) {
      console.error('Error fetching leaderboard data:', error);
    } finally {
      setLoadingLeaderboard(false);
    }
  };

  useEffect(() => {
    fetchLeaderboardData();
  }, []);

  return (
    <div className="mt-10">
      <h2 className="text-2xl lg:text-3xl font-bold text-center mb-8">Leaderboard</h2>
      {loadingLeaderboard ? (
        <div className="text-center">
          <p className="text-xl">Loading Leaderboard...</p>
          <div className="loader mt-4"></div>
        </div>
      ) : (
        <div className="container mx-auto px-4">
          <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
            <table className="w-full text-sm text-left text-gray-400">
              <thead className="text-xs uppercase bg-gray-700 text-gray-400">
                <tr>
                  <th scope="col" className="py-3 px-6">Rank</th>
                  <th scope="col" className="py-3 px-6">User</th>
                  <th scope="col" className="py-3 px-6">Karma (Minutes)</th>
                </tr>
              </thead>
              <tbody>
                {leaderboardData.map((userData, index) => (
                  <tr
                    key={userData.username}
                    className="bg-gray-800 border-b border-gray-700 hover:bg-gray-600"
                  >
                    <td className="py-4 px-6">{index + 1}</td> {/* Rank is index + 1 */}
                    <td className="py-4 px-6">{userData.username}</td>
                    <td className="py-4 px-6">{userData.karma.toFixed(2)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default Leader;
