import React, { useState,useEffect } from 'react';
import { FaTaxi, FaComment, FaBook, FaShoppingCart, FaUserTie, FaUserGraduate, FaBullhorn } from 'react-icons/fa';
import ChatPage from './ChatPage';
import CabShare from './CabShare/CabShare';
import Navbar from '../components/Navbar';
import BuySellPosts from './BuySell/BuySellPosts';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PostsPage from './posts/PostsPage';
import Loader from '../components/Loader';


const CommunityPage = () => {
  const [selectedFeature, setSelectedFeature] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (selectedFeature) {
      setLoading(true);
      // Simulate loading delay
      setTimeout(() => {
        setLoading(false);
      }, 1700); // You can adjust the delay time here
    }
  }, [selectedFeature]);



  // Sample mentor data
  const mentors = [
    {
      name: 'Dr. Aditi Sharma',
      position: 'AI Specialist',
      rate: '₹1500/hr',
      time: 'Available: Mon-Fri 6PM - 8PM',
      avatar: 'female', // assuming avatar type based on gender
    },
    {
      name: 'Rahul Mehta',
      position: 'Data Scientist',
      rate: '₹1200/hr',
      time: 'Available: Sat-Sun 4PM - 7PM',
      avatar: 'male',
    },
    {
      name: 'Priya Desai',
      position: 'Software Engineer',
      rate: '₹1000/hr',
      time: 'Available: Tue-Thu 5PM - 7PM',
      avatar: 'female',
    },
    {
      name: 'Aakash Patel',
      position: 'Cybersecurity Expert',
      rate: '₹1800/hr',
      time: 'Available: Mon-Sat 2PM - 5PM',
      avatar: 'male',
    },
  ];

  // Function to show toast notification when scheduling a session
  const scheduleMentorSession = (mentorName) => {
    toast.success(`Mentoring session with ${mentorName} booked successfully!`, {
     
      autoClose: 3000, // Automatically close the toast after 3 seconds
    });
  };

  const renderMentors = () => (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 mt-8">
      {mentors.map((mentor, index) => (
        <div
          key={index}
          className="bg-slate-900 p-6 rounded-lg shadow-lg neon-glow hover:scale-105 transform transition duration-500 cursor-pointer"
        >
          <div className="flex items-center mb-4">
            <div className="w-16 h-16 rounded-full bg-blue-500 overflow-hidden mr-4">
              {/* Placeholder avatar based on gender */}
              {mentor.avatar === 'male' ? (
                <FaUserTie className="w-full h-full text-white" />
              ) : (
                <FaUserGraduate className="w-full h-full text-white" />
              )}
            </div>
            <div>
              <h3 className="text-xl font-bold">{mentor.name}</h3>
              <p className="text-sm opacity-75">{mentor.position}</p>
            </div>
          </div>
          <p className="text-blue-400 font-semibold">{mentor.rate}</p>
          <p className="text-blue-300 opacity-80">{mentor.time}</p>

          {/* Schedule Now Button */}
          <button
            onClick={() => scheduleMentorSession(mentor.name)}
            className="bg-blue-500 text-white mt-4 px-4 py-2 rounded-lg hover:bg-blue-600 transition"
          >
            Schedule Now
          </button>
        </div>
      ))}
    </div>
  );

  const renderContent = () => {
    if (loading) {
      return <Loader />; // Show loader while loading
    }


    switch (selectedFeature) {
      case 'Find Study Mates':
        return <div className="text-lg">Find Study Mates Feature Coming Soon</div>;
      case 'Cab Share':
        return <CabShare />; // Full view of Cab Share component
      case 'Buy & Sell':
        return <BuySellPosts />;
      case 'Chat':
        return <ChatPage />; // Full view of Chat functionality
      case '1:1 Mentoring':
        return (
          <>
            <h2 className="text-2xl font-extrabold mb-6">1:1 Mentoring</h2>
            {renderMentors()}
          </>
        );

        case 'Posts & Announcements':
        return <PostsPage />;


      default:
        return (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 mt-16">
            {/* Cab Share Feature */}
            <div
              className="feature-card bg-slate-900 p-6 rounded-lg shadow-lg cursor-pointer hover:scale-105 transform transition duration-500 neon-glow"
              onClick={() => setSelectedFeature('Cab Share')}
            >
              <FaTaxi className="text-4xl mb-4 text-yellow-500" />
              <h3 className="text-xl font-bold">Cab Share</h3>
              <p>Find and share a cab with others.</p>
            </div>

            {/* Chat Feature */}
            <div
              className="feature-card bg-slate-900 p-6 rounded-lg shadow-lg cursor-pointer hover:scale-105 transform transition duration-500 neon-glow"
              onClick={() => setSelectedFeature('Chat')}
            >
              <FaComment className="text-4xl mb-4 text-green-500" />
              <h3 className="text-xl font-bold">Chat</h3>
              <p>Connect with others in the community.</p>
            </div>

            {/* Find Study Mates */}
            <div
              className="feature-card bg-slate-900 p-6 rounded-lg shadow-lg cursor-pointer hover:scale-105 transform transition duration-500 neon-glow"
              onClick={() => setSelectedFeature('Find Study Mates')}
            >
              <FaBook className="text-4xl mb-4 text-orange-500" />
              <h3 className="text-xl font-bold">Find Study Mates</h3>
              <p>Study with others for upcoming exams.</p>
            </div>

            {/* Buy & Sell */}
            <div
              className="feature-card bg-slate-900 p-6 rounded-lg shadow-lg cursor-pointer hover:scale-105 transform transition duration-500 neon-glow"
              onClick={() => setSelectedFeature('Buy & Sell')}
            >
              <FaShoppingCart className="text-4xl mb-4 text-pink-500" />
              <h3 className="text-xl font-bold">Buy & Sell</h3>
              <p>Buy and sell study materials.</p>
            </div>

            {/* 1:1 Mentoring */}
            <div
              className="feature-card bg-slate-900 p-6 rounded-lg shadow-lg cursor-pointer hover:scale-105 transform transition duration-500 neon-glow"
              onClick={() => setSelectedFeature('1:1 Mentoring')}
            >
              <FaUserTie className="text-4xl mb-4 text-blue-500" />
              <h3 className="text-xl font-bold">1:1 Mentoring</h3>
              <p>Get personalized 1:1 mentoring sessions.</p>
            </div>

            <div
              className="feature-card bg-slate-900 p-6 rounded-lg shadow-lg cursor-pointer hover:scale-105 transform transition duration-500 neon-glow"
              onClick={() => setSelectedFeature('Posts & Announcements')}
            >
              <FaBullhorn className="text-4xl mb-4 text-red-500" />
              <h3 className="text-xl font-bold">Posts & Announcements</h3>
              <p>View club posts, student projects, and announcements.</p>
            </div>


          </div>
        );
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-black via-slate-800 to-slate-900 text-white flex flex-col">
      {/* Navbar */}
      <Navbar />

      <div className="flex-1 p-8 mt-12">
        {selectedFeature ? (
          <button
            className="bg-slate-700 text-white px-4 py-2 rounded-lg mb-6 hover:bg-slate-600 transition"
            onClick={() => setSelectedFeature(null)}
          >
            Go Back
          </button>
        ) : (
          <h2 className="text-2xl flex-1 font-extrabold mb-6">Explore Exclusive Features</h2>
        )}
        {renderContent()}
      </div>

      {/* Toast Container */}
      <ToastContainer />

      {/* Footer */}
      <footer className="bg-slate-900 py-8 text-white mt-32">
        <div className="container mx-auto flex flex-col lg:flex-row justify-between items-center px-4 lg:px-0">
          <div className="lg:flex-1 text-center lg:text-left">
            <h4 className="font-bold text-lg mb-2">About Us</h4>
            <p className="text-sm opacity-70">
              We are dedicated to providing innovative tools to enhance your educational experience and help you succeed.
            </p>
          </div>

          <div className="lg:flex-1 text-center mt-8 lg:mt-0">
            <h4 className="font-bold text-lg mb-2">Quick Links</h4>
            <ul className="text-sm opacity-70">
              <li><a href="./" className="hover:underline">Home</a></li>
              <li><a href="./" className="hover:underline">Features</a></li>
              <li><a href="./" className="hover:underline">About</a></li>
              <li><a href="./" className="hover:underline">Contact</a></li>
            </ul>
          </div>

          <div className="lg:flex-1 text-center lg:text-right mt-8 lg:mt-0">
            <h4 className="font-bold text-lg mb-2">Subscribe to our newsletter</h4>
            <form className="sm:flex sm:items-center justify-center lg:justify-end">
              <input
                type="email"
                placeholder="Your email"
                className="p-2 rounded-lg bg-slate-800 text-sm focus:outline-none border border-slate-600 mr-2 w-full sm:w-auto"
              />
              <button
                type="submit"
                className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition mt-4 sm:mt-0"
              >
                Subscribe
              </button>
            </form>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default CommunityPage;
