import React, { useEffect } from 'react';
import { gsap } from 'gsap';
import { FaBook, FaGraduationCap, FaPencilAlt, FaChalkboardTeacher } from 'react-icons/fa';

const Loader = () => {
  useEffect(() => {
    const slider = gsap.timeline({ repeat: -1 });

    // Slide icons
    slider.to('.icon-slider', {
      x: '-100%', // Slide the icons to the left
      duration: 3,
      ease: 'power1.inOut',
    }).to('.icon-slider', {
      x: '0%', // Slide back to the start
      duration: 0,
      ease: 'none',
    });

    // Animate the colorful circles
    const circleElements = gsap.utils.toArray('.circle-element');
    circleElements.forEach((element) => {
      gsap.fromTo(element, {
        scale: 0,
        opacity: 0,
      }, {
        scale: 1,
        opacity: 1,
        duration: 1,
        ease: 'back.out(1.7)',
        stagger: 0.2,
      });
    });
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-b from-black via-slate-800 to-slate-900 text-white flex flex-col justify-center items-center">
      {/* Loading Message */}
      <h2 className="text-3xl font-bold mb-8 animate-pulse">VidyaMitra is loading for you...</h2>
      
      {/* Icon Slider */}
      <div className="relative w-64 h-24 overflow-hidden">
        <div className="flex space-x-8 icon-slider">
          {/* Icons */}
          <div className="text-6xl"><FaBook className="text-green-400" /></div>
          <div className="text-6xl"><FaGraduationCap className="text-yellow-400" /></div>
          <div className="text-6xl"><FaPencilAlt className="text-pink-400" /></div>
          <div className="text-6xl"><FaChalkboardTeacher className="text-teal-400" /></div>
        </div>
      </div>
      
      {/* Colorful Circles */}
      <div className="mt-8 flex space-x-4">
        <div className="circle-element w-10 h-10 rounded-full bg-red-400 animate-bounce"></div>
        <div className="circle-element w-10 h-10 rounded-full bg-blue-400 animate-bounce"></div>
        <div className="circle-element w-10 h-10 rounded-full bg-yellow-400 animate-bounce"></div>
        <div className="circle-element w-10 h-10 rounded-full bg-green-400 animate-bounce"></div>
      </div>

      {/* Subtext */}
      <p className="mt-4 opacity-70 text-sm">
        Please wait, we are preparing everything for your learning journey.
      </p>
    </div>
  );
};

export default Loader;
