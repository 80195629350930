import React, { useState, useEffect, useCallback } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { firestore } from '../../firebaseConfig';

const StudyNotes = () => {
  const [branch, setBranch] = useState('');
  const [semester, setSemester] = useState('');
  const [pdfs, setPdfs] = useState([]);

  const fetchPdfs = useCallback(async () => {
    if (branch && semester) {
      const q = query(
        collection(firestore, 'pdfs'),
        where('branch', '==', branch),
        where('semester', '==', semester)
      );
      const querySnapshot = await getDocs(q);
      const pdfList = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setPdfs(pdfList);
    }
  }, [branch, semester]);

  useEffect(() => {
    fetchPdfs();
  }, [fetchPdfs]);

  return (
    <div className="min-h-screen bg-gray-100 text-black flex flex-col items-center p-6">
      <h1 className="text-3xl font-bold mb-8">Study Notes</h1>

      <div className="w-full max-w-md mb-6">
        <label className="block text-lg font-medium text-gray-700">Branch:</label>
        <select
          onChange={(e) => setBranch(e.target.value)}
          className="form-select mt-1 block w-full border border-gray-300 rounded-lg p-2 bg-white"
        >
          <option value="">Select Branch</option>
          <option value="CSE">Computer Science Engineering (CSE)</option>
          <option value="ECE">Electronics and Communication Engineering (ECE)</option>
          <option value="ME">Mechanical Engineering (ME)</option>
          <option value="CE">Civil Engineering (CE)</option>
          <option value="EE">Electrical Engineering (EE)</option>
          <option value="IT">Information Technology (IT)</option>
          {/* Add more branches as needed */}
        </select>
      </div>

      <div className="w-full max-w-md mb-6">
        <label className="block text-lg font-medium text-gray-700">Semester:</label>
        <select
          onChange={(e) => setSemester(e.target.value)}
          className="form-select mt-1 block w-full border border-gray-300 rounded-lg p-2 bg-white"
        >
          <option value="">Select Semester</option>
          <option value="1st">1st Semester</option>
          <option value="2nd">2nd Semester</option>
          <option value="3rd">3rd Semester</option>
          <option value="4th">4th Semester</option>
          <option value="5th">5th Semester</option>
          <option value="6th">6th Semester</option>
          <option value="7th">7th Semester</option>
          <option value="8th">8th Semester</option>
          {/* Add more semesters as needed */}
        </select>
      </div>

      <div className="w-full max-w-md bg-white shadow-lg rounded-lg p-6">
        <h2 className="text-2xl font-bold mb-4 text-center">Available PDFs</h2>
        {pdfs.length > 0 ? (
          <ul className="space-y-3">
            {pdfs.map((pdf) => (
              <li key={pdf.id} className="mb-2">
                <a href={`/view-pdf/${pdf.id}`} className="text-blue-500 hover:underline">
                  {pdf.title}
                </a>
              </li>
            ))}
          </ul>
        ) : (
          <p className="text-gray-500 text-center">No PDFs available for this branch and semester.</p>
        )}
      </div>
    </div>
  );
};

export default StudyNotes;
