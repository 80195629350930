import React, { useState } from "react";
import { db, storage } from "../../firebaseConfig"; // Import Firebase storage
import { collection, addDoc } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage"; // Import storage functions
import { useNavigate } from "react-router-dom";
import Navbar from "../../components/Navbar";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "../../contexts/authContext"; // Import your authentication context

const CreateBuySell = () => {
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    price: "",
  });
  const [imageFile, setImageFile] = useState(null); // State to hold the selected image
  const [uploading, setUploading] = useState(false); // Upload state
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { currentUser } = useAuth(); // Get current logged-in user

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file); // Set the selected image file
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    const { title, description, price } = formData;

    // Validate form data
    if (!title || !description || !price || !imageFile) {
      setError("Please fill in all the required fields including the image");
      return;
    }

    // Check if currentUser is defined
    if (!currentUser || !currentUser.uid) {
      setError("You must be logged in to create a post.");
      return;
    }

    try {
      setUploading(true);

      // Step 1: Upload image to Firebase Storage
      const storageRef = ref(storage, `buySellImages/${imageFile.name}`); // Create a storage reference
      const uploadTask = uploadBytesResumable(storageRef, imageFile); // Upload the file

      // Step 2: Wait for image upload to finish and get the download URL
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Optional: Track progress if needed
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(`Upload is ${progress}% done`);
        },
        (error) => {
          console.error("Image upload failed:", error);
          setError("Image upload failed. Please try again.");
          setUploading(false);
        },
        async () => {
          // Get the download URL once upload completes
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

          // Step 3: Add post data to Firestore, including the image URL
          await addDoc(collection(db, "buySellPosts"), {
            title,
            description,
            price,
            imageUrl: downloadURL, // Save the image URL in Firestore
            createdAt: new Date(),
            creatorId: currentUser.uid, // Ensure currentUser is defined
          });

          toast.success("Buy & Sell post created successfully!");
          setUploading(false);
          
          // Reset form and navigate back
          setFormData({
            title: "",
            description: "",
            price: "",
          });
          setImageFile(null);
          setTimeout(() => navigate("/buySell"), 2000);
        }
      );
    } catch (error) {
      console.error("Error adding document: ", error);
      setError("Failed to create the post. Please try again.");
      setUploading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-black via-slate-800 to-slate-900 text-white flex flex-col">
      <Navbar />

      <div className="flex-1 flex flex-col items-center justify-center p-8">
        <h1 className="text-4xl font-extrabold mb-8">Create Buy & Sell Post</h1>

        {error && <p className="text-red-500 mb-4">{error}</p>}

        <form onSubmit={handleSubmit} className="bg-slate-800 p-8 rounded-lg shadow-lg w-full max-w-lg space-y-6">
          {/* Title */}
          <div>
            <input
              type="text"
              name="title"
              value={formData.title}
              onChange={handleInputChange}
              placeholder="Enter product title"
              className="bg-slate-700 text-white px-4 py-2 rounded-lg w-full focus:outline-none"
              required
            />
          </div>

          {/* Description */}
          <div>
            <textarea
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              placeholder="Enter product description"
              className="bg-slate-700 text-white px-4 py-2 rounded-lg w-full focus:outline-none"
              required
            />
          </div>

          {/* Price */}
          <div>
            <input
              type="number"
              name="price"
              value={formData.price}
              onChange={handleInputChange}
              placeholder="Enter product price"
              className="bg-slate-700 text-white px-4 py-2 rounded-lg w-full focus:outline-none"
              required
            />
          </div>

          {/* Image Upload */}
          <div>
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              className="text-white"
              required
            />
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            className="w-full bg-blue-500 text-white px-4 py-2 rounded-lg text-lg font-semibold hover:bg-blue-600 transition-transform transform hover:scale-105"
            disabled={uploading}
          >
            {uploading ? "Uploading..." : "Create Post"}
          </button>
        </form>

        {/* Toast container to display notifications */}
        <ToastContainer />
      </div>
    </div>
  );
};

export default CreateBuySell;
