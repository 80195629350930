import React, { useState, useEffect } from 'react';
import { auth, db } from '../../firebaseConfig';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { updateProfile, updatePassword } from 'firebase/auth';

import { gsap } from 'gsap';

const EditProfile = () => {
  const [displayName, setDisplayName] = useState('');
  const [password, setPassword] = useState('');
  const [branch, setBranch] = useState('');
  const [semester, setSemester] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');


  const branches = [
    "Computer Science", "Mechanical Engineering", "Civil Engineering", 
    "Electrical Engineering", "Electronics and Communication", "Chemical Engineering"
  ];

  const semesters = [1, 2, 3, 4, 5, 6, 7, 8];

  useEffect(() => {
    const fetchUserData = async () => {
      const user = auth.currentUser;
      if (user) {
        try {
          const userDoc = await getDoc(doc(db, 'users', user.uid));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setDisplayName(userData.displayName || '');
            setBranch(userData.branch || '');
            setSemester(userData.semester || '');
          }
        } catch (err) {
          console.error("Error fetching user data: ", err);
          setError("Failed to load user data");
        }
      }
    };

    fetchUserData();

    // Add GSAP animation on mount
    gsap.fromTo(
      ".edit-profile-container",
      { opacity: 0, y: 50 },
      { opacity: 1, y: 0, duration: 1, ease: "power3.out" }
    );
  }, []);

  const handleProfileUpdate = async (e) => {
    e.preventDefault();
    const user = auth.currentUser;

    if (user) {
      try {
        // Update the display name in Firebase Authentication
        await updateProfile(user, { displayName });

        // Update the password in Firebase Authentication if it's provided
        if (password) {
          await updatePassword(user, password);
        }

        // Update user data in Firestore (branch, semester, and displayName)
        await updateDoc(doc(db, 'users', user.uid), {
          displayName,
          branch,
          semester,
          updatedAt: new Date(),
        });

        setSuccess("Profile updated successfully!");
      } catch (err) {
        setError(err.message);
      }
    }
  };

  return (
    <div className="min-h-screen flex flex-col bg-gray-800">
      <div className="flex flex-1 flex-col md:flex-row">
        {/* VidyaMitra branding section with responsive height */}
        <div className="flex flex-col justify-center text-white bg-gray-900 p-6 md:p-10 h-40 md:h-auto md:flex-1">
          <h1 className="text-4xl md:text-6xl font-extrabold">VidyaMitra</h1>
          <p className="text-lg md:text-1xl mt-4 text-center max-w-xs">
            Edit your profile to keep your information up to date.
          </p>
        </div>

        {/* Edit Profile form section */}
        <div className="flex-1 flex items-center justify-center p-4">
          <div className="edit-profile-container bg-gray-900 p-6 md:p-8 rounded-lg shadow-lg w-full max-w-md transition-all duration-300">
            <h2 className="text-3xl md:text-4xl text-white mb-6">Edit Profile</h2>
            <form onSubmit={handleProfileUpdate}>
              <input 
                type="text"
                placeholder="Display Name"
                value={displayName}
                onChange={(e) => setDisplayName(e.target.value)}
                className="w-full p-3 mb-4 border border-gray-700 rounded bg-gray-800 text-white placeholder-gray-400 focus:ring-2 focus:ring-blue-500 transition-all"
              />

              <input 
                type="password"
                placeholder="New Password (optional)"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full p-3 mb-4 border border-gray-700 rounded bg-gray-800 text-white placeholder-gray-400 focus:ring-2 focus:ring-blue-500 transition-all"
              />

              {/* Branch selection */}
              <select 
                value={branch} 
                onChange={(e) => setBranch(e.target.value)} 
                className="w-full p-3 mb-4 border border-gray-700 rounded bg-gray-800 text-white placeholder-gray-400 focus:ring-2 focus:ring-blue-500 transition-all"
              >
                <option value="" disabled>Select Branch</option>
                {branches.map((branch, index) => (
                  <option key={index} value={branch}>{branch}</option>
                ))}
              </select>

              {/* Semester selection */}
              <select 
                value={semester} 
                onChange={(e) => setSemester(e.target.value)} 
                className="w-full p-3 mb-4 border border-gray-700 rounded bg-gray-800 text-white placeholder-gray-400 focus:ring-2 focus:ring-blue-500 transition-all"
              >
                <option value="" disabled>Select Semester</option>
                {semesters.map((sem, index) => (
                  <option key={index} value={sem}>{sem}</option>
                ))}
              </select>

              {error && <p className="text-red-400 mb-4">{error}</p>}
              {success && <p className="text-green-400 mb-4">{success}</p>}
              <button 
                type="submit" 
                className="w-full bg-blue-600 text-white p-3 rounded-lg hover:bg-blue-700 transition-transform transform hover:scale-105"
              >
                Update Profile
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProfile;
