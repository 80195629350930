import React from "react";
import './BorderBeam.css'; // Import necessary CSS or use inline styles

const BorderBeam = ({
  className = '',
  size = 200,
  duration = 15,
  borderWidth = 1.5,
  anchor = 90,
  colorFrom = "#34d399",  // Update colorFrom to match VidyaMitra theme (e.g., teal shade)
  colorTo = "#3b82f6",    // Update colorTo to match VidyaMitra theme (e.g., blue shade)
  delay = 0,
}) => {
  return (
    <div
      style={{
        "--size": `${size}px`,
        "--duration": `${duration}s`,
        "--border-width": `${borderWidth}px`,
        "--color-from": colorFrom,
        "--color-to": colorTo,
        "--delay": `-${delay}s`,
      }}
      className={`border-beam-container ${className}`}
    />
  );
};

export default BorderBeam;
