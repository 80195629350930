import React, { useState } from 'react';
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { auth, db } from '../../firebaseConfig'; // Import Firestore instance
import { doc, setDoc } from 'firebase/firestore'; // Firestore methods
import { useNavigate, Link } from 'react-router-dom'; 
import { gsap } from 'gsap';

const Register = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [branch, setBranch] = useState('');
  const [semester, setSemester] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const branches = [
    "Computer Science", "Mechanical Engineering", "Civil Engineering", 
    "Electrical Engineering", "Electronics and Communication", "Chemical Engineering"
  ];

  const semesters = [1, 2, 3, 4, 5, 6, 7, 8];

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Update the user's display name
      await updateProfile(user, { displayName });

      // Save user data (branch and semester) to Firestore
      await setDoc(doc(db, 'users', user.uid), {
        displayName,
        email,
        branch,
        semester,
        createdAt: new Date()
      });

      // Navigate to the homepage after successful registration
      navigate('/');
    } catch (err) {
      setError(err.message);
    }
  };

  // Adding animation on component mount using GSAP
  React.useEffect(() => {
    gsap.fromTo(
      ".register-container",
      { opacity: 0, y: 50 },
      { opacity: 1, y: 0, duration: 1, ease: "power3.out" }
    );
  }, []);

  return (
    <div className="min-h-screen flex flex-col bg-gray-800">
      <div className="flex flex-1 flex-col md:flex-row">
        {/* VidyaMitra branding section with responsive height */}
        <div className="flex flex-col justify-center text-white bg-gray-900 p-6 md:p-10 h-40 md:h-auto md:flex-1">
          <h1 className="text-4xl md:text-6xl font-extrabold">VidyaMitra</h1>
          <p className="text-lg md:text-1xl mt-4 text-center max-w-xs">
            Join us to enhance your learning experience with innovative tools and resources.
          </p>
        </div>

        {/* Registration form section */}
        <div className="flex-1 flex items-center justify-center p-4">
          <div className="register-container bg-gray-900 p-6 md:p-8 rounded-lg shadow-lg w-full max-w-md transition-all duration-300">
            <h2 className="text-3xl md:text-4xl text-white mb-6">Register</h2>
            <form onSubmit={handleRegister}>
              <input 
                type="text"
                placeholder="Display Name"
                value={displayName}
                onChange={(e) => setDisplayName(e.target.value)}
                className="w-full p-3 mb-4 border border-gray-700 rounded bg-gray-800 text-white placeholder-gray-400 focus:ring-2 focus:ring-blue-500 transition-all"
              />
              <input 
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full p-3 mb-4 border border-gray-700 rounded bg-gray-800 text-white placeholder-gray-400 focus:ring-2 focus:ring-blue-500 transition-all"
              />
              <input 
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full p-3 mb-4 border border-gray-700 rounded bg-gray-800 text-white placeholder-gray-400 focus:ring-2 focus:ring-blue-500 transition-all"
              />
              
              {/* Branch selection */}
              <select 
                value={branch} 
                onChange={(e) => setBranch(e.target.value)} 
                className="w-full p-3 mb-4 border border-gray-700 rounded bg-gray-800 text-white placeholder-gray-400 focus:ring-2 focus:ring-blue-500 transition-all"
              >
                <option value="" disabled>Select Branch</option>
                {branches.map((branch, index) => (
                  <option key={index} value={branch}>{branch}</option>
                ))}
              </select>

              {/* Semester selection */}
              <select 
                value={semester} 
                onChange={(e) => setSemester(e.target.value)} 
                className="w-full p-3 mb-4 border border-gray-700 rounded bg-gray-800 text-white placeholder-gray-400 focus:ring-2 focus:ring-blue-500 transition-all"
              >
                <option value="" disabled>Select Semester</option>
                {semesters.map((sem, index) => (
                  <option key={index} value={sem}>{sem}</option>
                ))}
              </select>

              {error && <p className="text-red-400 mb-4">{error}</p>}
              <button 
                type="submit" 
                className="w-full bg-blue-600 text-white p-3 rounded-lg hover:bg-blue-700 transition-transform transform hover:scale-105"
              >
                Register
              </button>
            </form>
            <p className="text-white mt-4">
              Already have an account? <Link to="/login" className="text-blue-400 hover:underline">Login</Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
