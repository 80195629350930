import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import Login from './pages/user/Login';
import Register from './pages/user/Register';
import ForgotPassword from './pages/ForgetPassword';
import About from './pages/AboutPage';
import ProtectedRoute from './components/ProtectedRoute';
import { AuthProvider } from './contexts/authContext';
import CommunityPage from './pages/CommunityPage';
import ChatPage from './pages/ChatPage';
import CreateCabShare from './pages/CabShare/CreateCabShare';
import CabShare from './pages/CabShare/CabShare';
import CreateBuySell from './pages/BuySell/CreateBuySell';
import BuySellPosts from './pages/BuySell/BuySellPosts';
import Chat from './pages/CabShare/Chat';
import AdminLogin from './pages/admins/adminlogin';
import AdminDashboard from './pages/admins/AdminDashboard';
import Leader from './pages/user/Leaderboard';
import EditProfile from './pages/user/editProfile';
import StudyZone from './pages/studyzone/StudyZone';
import StudyNotes from './pages/studyzone/StudyNotes';
import ViewPdf from './pages/studyzone/ViewPdf';


// Define the theme object


const App = () => {
  return (
    
      <AuthProvider>
        <Router>
          <Routes>
            {/* Public Routes */}
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/about" element={<About />} />
            
            {/* Admin Routes */}
            <Route path="/admin-login" element={<AdminLogin />} />
            <Route path="/admin-dashboard" element={
              <ProtectedRoute>
                <AdminDashboard />
              </ProtectedRoute>
            } />
            
            {/* Protected Routes */}
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <HomePage />
                </ProtectedRoute>
              }
            />
            <Route path="/studyzone" element={<StudyZone />} />

            {/* Community and Chat Routes */}
            <Route
              path="/community"
              element={
                <ProtectedRoute>
                  <CommunityPage />
                </ProtectedRoute>
              }
            />

            <Route
              path="/chat/:cabShareId"
              element={
                <ProtectedRoute>
                  <ChatPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/chat/buy-sell/:postId"
              element={
                <ProtectedRoute>
                  <Chat />
                </ProtectedRoute>
              }
            />

            {/* Cab Share Pages */}
            <Route
              path="/create-cab-share"
              element={
                <ProtectedRoute>
                  <CreateCabShare />
                </ProtectedRoute>
              }
            />
            <Route
              path="/cabShare"
              element={
                <ProtectedRoute>
                  <CabShare />
                </ProtectedRoute>
              }
            />

            {/* Buy & Sell Pages */}
            <Route path="/create-buy-sell" element={<CreateBuySell />} />
            <Route path="/buy-sell" element={<BuySellPosts />} />
            <Route path="/leader" element={<Leader />} />

            {/* StudyNotes and ViewPdf Routes */}
            <Route path="/studynotes" element={<StudyNotes />} />
            <Route path="/view-pdf/:pdfId" element={<ViewPdf />} />
            <Route path="/edit-profile" element={<EditProfile />} />
          </Routes>
        </Router>
      </AuthProvider>

  );
};

export default App;
