import React, { useState, useEffect } from "react";
import { useLocation,  } from "react-router-dom";
import { db } from "../../firebaseConfig"; // Firestore config
import { collection, addDoc, getDocs, query, where, orderBy } from "firebase/firestore"; // Firestore functions
import { useAuth } from "../../contexts/authContext"; // Assuming you're using auth context

const Chat = () => {
  const { currentUser } = useAuth(); // Get logged-in user
  // Get the seller's userId from the route
  const location = useLocation();
  const { postId, creatorId } = location.state || {}; // Safely get post and creator data
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");

  // Fetch chat messages
  useEffect(() => {
    const fetchMessages = async () => {
      const chatQuery = query(
        collection(db, "chats"),
        where("postId", "==", postId),
        orderBy("createdAt", "asc")
      );
      const querySnapshot = await getDocs(chatQuery);
      setMessages(querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    };
    fetchMessages();
  }, [postId]);

  // Handle sending new message
  const handleSendMessage = async () => {
    if (newMessage.trim() === "") return;

    // Check if creatorId is defined
    if (!creatorId) {
      console.error("Error: creatorId is undefined.");
      return;
    }

    try {
      await addDoc(collection(db, "chats"), {
        postId,
        creatorId, // Ensure this is defined
        senderId: currentUser?.uid, // Optional chaining in case currentUser is undefined
        message: newMessage,
        createdAt: new Date(),
      });
      setNewMessage(""); // Clear message input after sending
    } catch (error) {
      console.error("Error sending message: ", error);
    }
  };

  return (
    <div className="flex flex-col h-screen bg-gray-800 text-white p-4">
      <div className="flex-1 overflow-y-auto p-2 bg-gray-900 rounded-lg">
        {messages.map((msg) => (
          <p key={msg.id} className={`my-2 p-2 rounded-md ${msg.senderId === currentUser?.uid ? 'bg-blue-600 text-white self-end' : 'bg-gray-700 text-white self-start'}`}>
            {msg.message}
          </p>
        ))}
      </div>

      <div className="flex items-center mt-4">
        <input
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder="Type your message"
          className="flex-1 p-2 rounded-md bg-gray-700 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <button onClick={handleSendMessage} className="ml-2 bg-blue-600 text-white p-2 rounded-md hover:bg-blue-700 transition duration-200">
          Send
        </button>
      </div>
    </div>
  );
};

export default Chat;
