import React, { useState, useEffect } from 'react';
import { FaBars, FaTimes, FaUser, FaSignOutAlt, FaEdit } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { auth, firestore } from '../firebaseConfig';
import { signOut } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';

const Navbar = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isUserMenuOpen, setUserMenuOpen] = useState(false);
  const [userRank, setUserRank] = useState(null);
  const [displayName, setDisplayName] = useState('User');
  const navigate = useNavigate();

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const toggleUserMenu = () => {
    setUserMenuOpen(!isUserMenuOpen);
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/login');
    } catch (err) {
      console.error('Logout error:', err);
    }
  };

  // Fetch user rank and display name from Firestore
  const fetchUserRank = async () => {
    if (auth.currentUser) {
      const userRef = doc(firestore, "users", auth.currentUser.uid);
      const userSnap = await getDoc(userRef);

      if (userSnap.exists()) {
        const data = userSnap.data();
        setUserRank(data.rank); 
        setDisplayName(data.displayName || 'User'); 
      }
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        fetchUserRank();
      } else {
        // Reset state when user is logged out
        setDisplayName('User');
        setUserRank(null);
      }
    });

    return () => unsubscribe(); // Clean up subscription on unmount
  }, []);

  const renderRankBadge = (rank) => {
    switch (rank) {
      case 'gold':
        return <span className="text-yellow-500">🏆 Gold</span>;
      case 'silver':
        return <span className="text-gray-300">🥈 Silver</span>;
      case 'bronze':
        return <span className="text-orange-500">🥉 Bronze</span>;
      default:
        return <span className="text-orange-500">🥉 Bronze</span>; 
    }
  };

  return (
    <header className="navbar fixed top-0 w-full bg-black bg-opacity-80 py-4 px-6 lg:px-8 shadow-lg z-10 flex items-center justify-between">
      <div className="flex items-center">
        <div className="bg-blue-500 rounded-full h-8 w-8 flex items-center justify-center text-white font-bold text-xl">
          V
        </div>
        <h1 className="text-2xl lg:text-3xl font-extrabold text-white ml-2">Vidyamitra</h1>
      </div>

      <nav className={`hidden lg:flex space-x-6 lg:space-x-12 ${isMobileMenuOpen ? 'block' : 'hidden'} lg:block`}>
        <Link to="/" className="nav-link hover:text-blue-400 transition-transform transform hover:scale-105">HOME</Link>
        <Link to="/studyzone" className="nav-link hover:text-blue-400 transition-transform transform hover:scale-105">STUDY ZONE</Link>
        <Link to="/community" className="nav-link hover:text-blue-400 transition-transform transform hover:scale-105">COMMUNITY</Link>
        <Link to="/about" className="nav-link hover:text-blue-400 transition-transform transform hover:scale-105">ABOUT US</Link>
        <Link to="/leader" className="nav-link hover:text-blue-400 transition-transform transform hover:scale-105">LEADERBOARD</Link>
      </nav>

      <div className="flex items-center space-x-4">
        <button className="lg:hidden text-white text-2xl" onClick={toggleMobileMenu}>
          {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
        </button>

        <div className="relative">
          <button className="flex items-center text-white text-2xl" onClick={toggleUserMenu}>
            <FaUser />
          </button>
          {isUserMenuOpen && (
            <div className="absolute right-0 mt-2 bg-white text-black p-4 rounded-lg shadow-lg">
              <p className="text-lg font-semibold">
                Welcome, {displayName}! {renderRankBadge(userRank)}
              </p>
              <Link
                to="/edit-profile"
                className="mt-2 flex items-center text-blue-500 hover:text-blue-700 transition-transform transform hover:scale-105"
                onClick={toggleUserMenu}
              >
                <FaEdit className="mr-2" /> Edit Profile
              </Link>
              <button
                onClick={handleLogout}
                className="mt-2 flex items-center text-red-500 hover:text-red-700 transition-transform transform hover:scale-105"
              >
                <FaSignOutAlt className="mr-2" /> Logout
              </button>
            </div>
          )}
        </div>
      </div>

      {/* Mobile Menu */}
      <div className={`lg:hidden fixed top-0 left-0 w-full h-full bg-black bg-opacity-90 flex flex-col items-center justify-center ${isMobileMenuOpen ? 'block' : 'hidden'}`}>
        <button className="text-white text-3xl mb-4" onClick={toggleMobileMenu}>
          <FaTimes />
        </button>
        <nav className="flex flex-col items-center space-y-4">
          <Link to="/" onClick={toggleMobileMenu} className="nav-link text-white hover:text-blue-400 transition-transform transform hover:scale-105">HOME</Link>
          <Link to="/studyzone" onClick={toggleMobileMenu} className="nav-link text-white hover:text-blue-400 transition-transform transform hover:scale-105">STUDYZONE</Link>
          <Link to="/community" onClick={toggleMobileMenu} className="nav-link text-white hover:text-blue-400 transition-transform transform hover:scale-105">COMMUNITY</Link>
          <Link to="/about" onClick={toggleMobileMenu} className="nav-link text-white hover:text-blue-400 transition-transform transform hover:scale-105">ABOUT US</Link>
        </nav>
      </div>
    </header>
  );
};

export default Navbar;
