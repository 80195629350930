import React, { useState, useEffect, useRef } from 'react';
import { doc, getDoc, collection, getDocs } from 'firebase/firestore';
import { auth, firestore } from '../../firebaseConfig';
import { FaMedal, FaBook, FaQuestionCircle, FaComments, FaPencilAlt } from 'react-icons/fa'; 
import { Link } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { gsap } from 'gsap';
import Navbar from '../../components/Navbar';
import avatarImage from '../../assets/9440461.jpg';
import AIcuratedNotes from './aiNotes'; // Importing AI-curated notes component


const StudyZone = () => {
  const [userData, setUserData] = useState(null);
  const [topNotes, setTopNotes] = useState([]);
  const [userRank, setUserRank] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const rankRef = useRef(null);
  const featureBoxesRef = useRef(null);

  const fetchUserData = async (userId) => {
    try {
      const userRef = doc(firestore, 'users', userId);
      const userSnap = await getDoc(userRef);

      if (userSnap.exists()) {
        setUserData(userSnap.data());
      }
    } catch (err) {
      setError("Error fetching user data");
    }
  };

  const fetchTopNotes = async (userId) => {
    try {
      const notesRef = doc(firestore, 'notes', userId);
      const notesSnap = await getDoc(notesRef);

      if (notesSnap.exists()) {
        setTopNotes(notesSnap.data().topNotes || []);
      }
    } catch (err) {
      setError("Error fetching notes");
    }
  };

  const calculateRanks = async () => {
    try {
      const userTimeRef = collection(firestore, 'userTimeSpent');
      const userTimeSnap = await getDocs(userTimeRef);
      const usersWithTime = userTimeSnap.docs.map((doc) => ({
        id: doc.id.split('_')[0],
        timeSpent: doc.data().timeSpent || 0,
      }));

      usersWithTime.sort((a, b) => b.timeSpent - a.timeSpent);

      const rankMap = {};
      usersWithTime.forEach((user, index) => {
        rankMap[user.id] = index + 1;
      });

      if (auth.currentUser) {
        const currentUserRank = rankMap[auth.currentUser.uid] || 0;
        setUserRank(currentUserRank);
      }
    } catch (err) {
      console.error("Error calculating ranks:", err);
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchUserData(user.uid);
        fetchTopNotes(user.uid);
        calculateRanks();
        setLoading(false);
      } else {
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    gsap.from(rankRef.current, { opacity: 0, y: -50, duration: 1, ease: 'power3.out' });
    gsap.from(featureBoxesRef.current, { opacity: 0, scale: 0.9, duration: 1, delay: 1, ease: 'back.out(1.7)' });
  }, [loading]);

  const renderRankBadge = (rank) => {
    switch (rank) {
      case 1:
        return <span className="text-yellow-500"><FaMedal /> Gold</span>;
      case 2:
        return <span className="text-gray-300"><FaMedal /> Silver</span>;
      case 3:
        return <span className="text-orange-500"><FaMedal /> Bronze</span>;
      default:
        return <span className="text-gray-500"><FaMedal /> Participant</span>;
    }
  };

  if (loading) {
    return <div className="text-white text-center">Loading...</div>;
  }

  if (error) {
    return <div className="text-white text-center">Error: {error}</div>;
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-black via-slate-800 to-slate-900 text-white flex flex-col py-20 px-4">
      {/* Navbar */}
      <Navbar />

      {/* Leaderboard Button */}
      <div className="flex justify-end mb-4">
        <Link to="/leaders" className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition">
          View Leaderboard
        </Link>
      </div>

      {/* User Section */}
     
      <div ref={rankRef} className="bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500 p-6 rounded-lg mb-8 shadow-md w-full md:w-2/3 mx-auto flex justify-between items-center">
        {/* Left side: User details */}
        
        <div>
          <h2 className="text-3xl font-bold mb-4">Hi, {userData?.displayName || 'User'}!</h2>
          <p className="text-white">Branch: {userData?.branch || 'N/A'}</p>
          <p className="text-white">Semester: {userData?.semester || 'N/A'}</p>
          <div className="mt-4">
            <p className="text-2xl text-pretty">Rank: {userRank}</p>
            {renderRankBadge(userRank)}
          </div>
        </div>

        {/* Right side: Avatar image */}
        <div className="ml-4">
          <img
            src={avatarImage}
            alt="User Avatar"
            className="w-28 h-28 rounded-full shadow-lg"
          />
        </div>
      </div>

      {/* AI-Curated Notes Section */}
      <AIcuratedNotes userData={userData} topNotes={topNotes} />

      {/* Feature Boxes */}
      <div ref={featureBoxesRef} className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 w-full mx-auto">
        <div className="bg-blue-500 p-6 rounded-lg shadow-md hover:bg-blue-600 transition transform hover:scale-105 h-56">
          <Link to="/pyqs" className="text-white text-2xl font-semibold flex items-center">
            <FaQuestionCircle className="mr-2" /> Previous Year Questions (PYQs)
          </Link>
          <p className="mt-2 text-white">Access past year papers and improve your preparation.</p>
        </div>
        <div className="bg-pink-500 p-6 rounded-lg shadow-md hover:bg-pink-600 transition transform hover:scale-105 h-56">
          <Link to="/studynotes" className="text-white text-2xl font-semibold flex items-center">
            <FaBook className="mr-2" /> Study Material
          </Link>
          <p className="mt-2 text-white">Download important notes and study guides.</p>
        </div>
        <div className="bg-orange-500 p-6 rounded-lg shadow-md hover:bg-orange-600 transition transform hover:scale-105 h-56">
          <Link to="/doubts" className="text-white text-2xl font-semibold flex items-center">
            <FaComments className="mr-2" /> Ask Doubts
          </Link>
          <p className="mt-2 text-white">Get your doubts cleared by peers and teachers.</p>
        </div>
        <div className="bg-violet-500 p-6 rounded-lg shadow-md hover:bg-violet-600 transition transform hover:scale-105 h-56">
          <Link to="/quiz" className="text-white text-2xl font-semibold flex items-center">
            <FaPencilAlt className="mr-2" /> Quiz Practice
          </Link>
          <p className="mt-2 text-white">Test your knowledge with quizzes and track your progress.</p>
        </div>
      </div>
    </div>
  );
};

export default StudyZone;
