import React, { useState, useEffect } from "react";
import { db } from "../../firebaseConfig"; // Firestore config
import { collection, getDocs, query, orderBy } from "firebase/firestore"; // Firestore functions
import { Fab } from "@mui/material";

import { useNavigate } from "react-router-dom";
import { FaTaxi } from "react-icons/fa"; // Taxi icon from React Icons

const CabShare = () => {
  const [cabShares, setCabShares] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCab, setSelectedCab] = useState(null);
  const [sorted, setSorted] = useState(false); // To toggle sorting by date and time
  const [loading, setLoading] = useState(true); // Loading state for database fetching
  const navigate = useNavigate();

  // Fetch all cab shares from Firestore
  useEffect(() => {
    const fetchCabShares = async () => {
      const cabShareQuery = query(collection(db, "cabShares"), orderBy("dateTime")); // Sorted by date and time
      const querySnapshot = await getDocs(cabShareQuery);
      setCabShares(querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
      setLoading(false); // Set loading to false once data is fetched
    };
    fetchCabShares();
  }, []);

  // Handle search query
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Toggle sort order (ascending or descending by date/time)
  const toggleSort = () => {
    setSorted(!sorted);
  };

  // Filter and search cab shares based on input
  const filteredCabShares = cabShares
    .filter(
      (cab) =>
        searchQuery === "" ||
        cab.fromLocation.toLowerCase().includes(searchQuery.toLowerCase()) ||
        cab.toLocation.toLowerCase().includes(searchQuery.toLowerCase())
    )
    .sort((a, b) => {
      const dateA = new Date(a.dateTime);
      const dateB = new Date(b.dateTime);
      return sorted ? dateB - dateA : dateA - dateB;
    });

  const handleStartConversation = (cab) => {
    navigate(`/chat/${cab.id}`); // Navigate to the chat page with cabShareId
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-black via-slate-800 to-slate-900 text-black flex flex-col">
      <main className="flex-1 p-6 text-left">
        <h1 className="text-2xl lg:text-3xl font-bold mb-4 text-white">Cab Share</h1>

        {/* Search Bar */}
        <input
          type="text"
          placeholder="Search by location"
          value={searchQuery}
          onChange={handleSearchChange}
          className="bg-gray-200 text-black px-4 py-2 rounded-lg mb-4 w-full"
        />

        <div className="mb-4 flex gap-2">
          <button onClick={toggleSort} className="bg-gray-300 text-black px-4 py-2 rounded-lg">
            {sorted ? "Sort: Newest First" : "Sort: Oldest First"}
          </button>
        </div>

        {/* Loading state with animated icon */}
        {loading ? (
          <div className="flex justify-center items-center mt-10">
            <FaTaxi className="text-4xl text-blue-400 animate-slide" />
            <p className="text-white ml-4">Fetching cab shares...</p>
          </div>
        ) : (
          // Display filtered and sorted cab shares after loading
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            {filteredCabShares.length === 0 ? (
              <p className="text-white">No cab shares available</p>
            ) : (
              filteredCabShares.map((cab) => (
                <div
                  key={cab.id}
                  className={`bg-white p-4 rounded-lg shadow border border-gray-200 cursor-pointer ${selectedCab?.id === cab.id ? "bg-blue-100" : ""}`}
                  onClick={() => setSelectedCab(cab)}
                >
                  <h3 className="text-lg font-bold">From: {cab.fromLocation}</h3>
                  <h3 className="text-lg font-bold">To: {cab.toLocation}</h3>
                  <p className="text-md">Date & Time: {new Date(cab.dateTime).toLocaleString()}</p>
                  <p className="text-md">Description: {cab.description}</p>
                  {selectedCab?.id === cab.id && (
                    <button
                      onClick={() => handleStartConversation(cab)}
                      className="bg-blue-500 text-white px-4 py-2 rounded-lg mt-4"
                    >
                      Start a Conversation
                    </button>
                  )}
                </div>
              ))
            )}
          </div>
        )}

        {/* Floating Button to Create a New Cab Share */}
        <Fab
          color="default"
          className="fab-with-animation"
          style={{ position: "fixed", bottom: "30px", right: "30px", width:"120px",height:"40px", borderRadius:"4px"}}
          onClick={() => navigate("/create-cab-share")}
        >
            <h1>New Post</h1>
          <FaTaxi className="fab-icon" />
          
        </Fab>
      </main>

      {/* Custom Styles */}
      <style jsx>{`
        .fab-with-animation {
          animation: breathing 2s infinite;
        }

        @keyframes breathing {
          0% {
            transform: scale(1);
          }
          50% {
            transform: scale(1.1);
          }
          100% {
            transform: scale(1);
          }
        }

        .fab-icon {
          animation: drive 1.5s infinite;
        }

        @keyframes drive {
          0%, 100% {
            transform: translateX(0);
          }
          50% {
            transform: translateX(10px);
          }
        }
      `}</style>
    </div>
  );
};

export default CabShare;
