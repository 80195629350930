import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebaseConfig';
import { gsap } from 'gsap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { FaEye } from 'react-icons/fa'; // Icon for "See More"
import Modal from 'react-modal'; // Modal for showing full details

Modal.setAppElement('#root'); // Ensure accessibility

const PostsPage = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedPost, setSelectedPost] = useState(null); // For modal data
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal state

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'club_posts')); // Assuming your collection name is 'club_posts'
        const postsData = querySnapshot.docs.map((doc) => doc.data());
        setPosts(postsData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching posts:', error);
        setLoading(false);
      }
    };

    fetchPosts();
  }, []);

  useEffect(() => {
    gsap.fromTo('.post-card', { opacity: 0 }, { opacity: 1, duration: 1, stagger: 0.2 });
  }, [posts]);

  const openModal = (post) => {
    setSelectedPost(post);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  if (loading) {
    return <div className="p-8">Loading posts...</div>;
  }

  return (
    <div className="p-8">
      <h2 className="text-2xl font-extrabold mb-6">Posts and Announcements</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {posts.length > 0 ? (
          posts.map((post, index) => (
            <div
              key={index}
              className="post-card bg-slate-800 p-6 rounded-lg shadow-lg hover:shadow-xl transition-all duration-300 cursor-pointer"
              onClick={() => openModal(post)}
            >
              <h3 className="text-xl font-bold">{post.title}</h3>
              <p className="text-sm opacity-75">Last Date: <span className="text-large text-red-600">{new Date(post.date).toLocaleDateString()}</span></p>
              <p className="mt-4">{post.shortDescription}</p>
              <div className="mt-4 flex justify-end items-center">
                <FaEye className="text-white hover:text-slate-500 transition-colors duration-300" size={20} />
              </div>
            </div>
          ))
        ) : (
          <p>No posts available</p>
        )}
      </div>

      {/* Modal for Full Details */}
      {selectedPost && (
        <Modal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          className="modal bg-slate-200 p-6 rounded-lg shadow-lg max-w-3xl mx-auto my-16"
          overlayClassName="modal-overlay bg-gray-900 bg-opacity-75 fixed inset-0 flex justify-center items-center"
        >
          <h2 className="text-2xl font-bold mb-4">{selectedPost.title}</h2>
          <p className="text-sm opacity-75 mb-2">Posted on: <span className="text-red-600">{new Date(selectedPost.date).toLocaleDateString()}</span></p>
          <p className="mb-4">{selectedPost.shortDescription}</p>
          <ReactQuill
            value={selectedPost.longDescription}
            readOnly={true}
            theme="snow"
          />
          <button onClick={closeModal} className="mt-4 bg-red-500 px-4 py-2 rounded-lg text-white hover:bg-red-700 transition-colors duration-300">
            Close
          </button>
        </Modal>
      )}
    </div>
  );
};

export default PostsPage;
