import React, { Suspense, useRef } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { OrbitControls, useGLTF, Stars, Html } from '@react-three/drei';

// Earth Model Component
const EarthModel = () => {
  const { scene } = useGLTF('/models/earth.glb'); // Load the model
  const modelRef = useRef(); // Create a reference for the model

  // Rotate the model on every frame to simulate rotation
  useFrame(() => {
    if (modelRef.current) {
      modelRef.current.rotation.y += 0.003; // Adjust rotation speed for realism
    }
  });

  return (
    <primitive ref={modelRef} object={scene} scale={1.8} position={[0, 0, 0]} />
  );
};

const EarthCanvas = () => {
  return (
    <Canvas>
      <Suspense fallback={<Html><div className="text-white"></div></Html>}>
        {/* Ambient light for soft, even lighting */}
        <ambientLight intensity={2.3} />

        {/* Sun simulation with point light */}
        <pointLight
          intensity={10} // Stronger light for sun simulation
          position={[10, 10, 10]} // Light source far away to simulate the sun
          color={'#ffffff'} // Bright white light
          castShadow
        />

        {/* Back light for subtle shadow detailing */}
        <pointLight
          intensity={1.6} // Subtle lighting from the back for shadow depth
          position={[-5, -5, -5]} // Opposite direction for realistic shading
          color={'#blue'}
        />

        {/* Add the Earth Model */}
        <EarthModel />

        {/* Title and subtitle overlaying the globe */}
       
        {/* Stars in the background */}
        <Stars
          radius={300} // Larger radius for stars
          depth={60} // Greater depth for star field
          count={5000} // Fewer stars for realism
          factor={2} // Smaller stars
          saturation={0.1} // Less color for realistic space effect
          fade // Fade effect for better depth perception
        />

        {/* Orbit controls with zoom enabled */}
        <OrbitControls enableZoom={true} />

        {/* Fog for depth, simulating space atmosphere */}
        <fog attach="fog" args={['#000000', 10, 50]} />
      </Suspense>
    </Canvas>
  );
};

export default EarthCanvas;
