// src/components/ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/authContext'; // Assume you have an auth context for managing authentication

const ProtectedRoute = ({ children }) => {
  const { currentUser } = useAuth(); // Retrieve the current user

  if (!currentUser) {
    // If not logged in, redirect to login page
    return <Navigate to="/login" />;
  }

  return children; // If logged in, show the requested page
};

export default ProtectedRoute;
