import React, { useRef, useEffect } from 'react';
import { FaRobot } from 'react-icons/fa'; 
import { gsap } from 'gsap';
 // Import the BorderBeam component

const AIcuratedNotes = ({ userData, topNotes = [] }) => {
  const notesRef = useRef(null);

  // GSAP animation for AI elements
  useEffect(() => {
    const robotIcon = notesRef.current.querySelector('.ai-icon');
    const notes = notesRef.current.querySelectorAll('.note-card');

    // AI icon glowing effect
    gsap.to(robotIcon, { 
      duration: 1.5, 
      repeat: -1, 
      yoyo: true, 
      opacity: 0.7,
      ease: "power2.inOut"
    });

    // Notes pulsing effect
    gsap.fromTo(notes, {
      opacity: 0.9,
    }, {
      duration: 1.5, 
      scale: 1.02,
      yoyo: true,
      repeat: -1,
      ease: "power2.inOut",
    });
  }, []);

  return (
    <div className="relative w-full md:w-3/5 mx-auto"> {/* Parent container */}
      {/* Updated BorderBeam */}
      <div ref={notesRef} className="bg-gradient-to-r from-gray-900 to-gray-800 p-6 rounded-lg mb-8 shadow-lg relative overflow-hidden flex flex-col items-start border border-teal-500 ai-box">
      
        <h3 className="text-3xl font-semibold mb-4 flex items-center text-white">
          <FaRobot className="mr-2 text-teal-400 ai-icon animate-spin-slow" /> 
          AI-curated Top Notes from {userData?.branch || 'your batch'} Students
        </h3>

        {/* Live AI Pulsing Dots */}
        <div className="absolute top-0 right-0 m-4 flex space-x-2">
          <span className="bg-teal-400 rounded-full w-3 h-3 animate-pulse"></span>
          <span className="bg-blue-400 rounded-full w-3 h-3 animate-pulse delay-75"></span>
          <span className="bg-purple-400 rounded-full w-3 h-3 animate-pulse delay-150"></span>
        </div>

        {/* Updated Note Cards */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 w-full">
          {topNotes.length > 0 ? (
            topNotes.map((note, index) => (
              <div 
                key={index} 
                className="bg-gradient-to-r from-indigo-500 to-purple-600 p-4 rounded-lg shadow-md hover:shadow-xl transition-all transform hover:scale-105 note-card relative">
                {/* Futuristic glow around the card */}
                <div className="absolute inset-0 rounded-lg border-2 border-transparent hover:border-indigo-400 transition-all duration-500"></div>

                {/* Note Content */}
                <p className="text-white font-semibold">{note.pdfName}</p>
                <span className="text-gray-200 text-sm">{note.uploadDate || 'Date not available'}</span>
              </div>
            ))
          ) : (
            <p className="text-gray-300">No top notes available</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default AIcuratedNotes;
