import React, { useState } from 'react';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../../firebaseConfig';
import ReactQuill from 'react-quill'; 
import 'react-quill/dist/quill.snow.css';

const ClubPostForm = () => {
  const [clubTitle, setClubTitle] = useState('');
  const [shortDescription, setShortDescription] = useState('');
  const [longDescription, setLongDescription] = useState('');
  const [clubDate, setClubDate] = useState('');
  const [success, setSuccess] = useState(false);

  const handleClubPostSubmit = async (e) => {
    e.preventDefault();
    if (!clubTitle || !shortDescription || !longDescription || !clubDate) {
      alert('Please fill all the fields.');
      return;
    }

    try {
      await addDoc(collection(db, 'club_posts'), {
        title: clubTitle,
        shortDescription,
        longDescription,
        date: clubDate,
        postedAt: new Date(),
      });

      setSuccess(true);
      setClubTitle('');
      setShortDescription('');
      setLongDescription('');
      setClubDate('');
    } catch (error) {
      console.error('Error adding club post', error);
    }
  };

  return (
    <form onSubmit={handleClubPostSubmit} className="mt-8 space-y-4 w-full max-w-md">
      <input
        type="text"
        placeholder="Club Post Title"
        value={clubTitle}
        onChange={(e) => setClubTitle(e.target.value)}
        className="w-full p-3 bg-gray-700 rounded text-white"
      />

      <textarea
        placeholder="Short Description"
        value={shortDescription}
        onChange={(e) => setShortDescription(e.target.value)}
        className="w-full p-3 bg-gray-700 rounded text-white"
        rows="3"
      ></textarea>

      <ReactQuill
        theme="snow"
        value={longDescription}
        onChange={setLongDescription}
        className="bg-white text-black"
        placeholder="Write long description here..."
      />

      <input
        type="date"
        value={clubDate}
        onChange={(e) => setClubDate(e.target.value)}
        className="w-full p-3 bg-gray-700 rounded text-white"
      />

      <button
        type="submit"
        className="bg-green-500 p-3 rounded-lg text-white"
      >
        Add Club Post
      </button>

      {success && <p className="text-green-500 mt-4">Club post added successfully!</p>}
    </form>
  );
};

export default ClubPostForm;
