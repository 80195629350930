import React, { useEffect, useState, useCallback } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { firestore, auth } from '../../firebaseConfig';
import { useParams, useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import Navbar from '../../components/Navbar'; // Adjust the import path as necessary
import axios from 'axios';
import { FaRobot } from 'react-icons/fa'; // AI Icon

const ViewPdf = () => {
  const { pdfId } = useParams();
  const [pdf, setPdf] = useState(null);
  const [summary, setSummary] = useState('');
  const [loadingSummary, setLoadingSummary] = useState(false);
  const navigate = useNavigate();

  // Your OpenAI API key
  const apiKey = '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';

  // Fetch PDF data
  const fetchPdf = useCallback(async () => {
    try {
      const pdfRef = doc(firestore, 'pdfs', pdfId);
      const pdfSnap = await getDoc(pdfRef);
      if (pdfSnap.exists()) {
        setPdf(pdfSnap.data());
      }
    } catch (err) {
      console.error('Error fetching PDF:', err);
    }
  }, [pdfId]);

  // Fetch summary of the PDF
  const fetchSummary = async () => {
    if (!pdf) return;
    setLoadingSummary(true);
    try {
      const response = await axios.post('https://api.openai.com/v1/chat/completions', {
        model: "gpt-3.5-turbo",
        messages: [
          { role: "user", content: `Summarize the following PDF content: ${pdf.fileUrl}` },
        ],
        max_tokens: 100,
      }, {
        headers: {
          'Authorization': `Bearer ${apiKey}`,
          'Content-Type': 'application/json',
        },
      });
      setSummary(response.data.choices[0].message.content.trim());
    } catch (err) {
      console.error('Error fetching summary:', err);
    } finally {
      setLoadingSummary(false);
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchPdf(); // Fetch PDF details
      } else {
        navigate('/login');
      }
    });
    return () => unsubscribe();
  }, [fetchPdf, navigate]);

  return (
    <div className="min-h-screen bg-slate-200 flex flex-col justify-start items-center relative">
      <Navbar /> {/* Render your Navbar */}
      <h1 className="text-2xl font-bold mt-4">PDF Viewer</h1>

      <div className="flex w-full max-w-4xl h-[calc(90vh-64px)] mt-8 bg-white shadow-lg rounded-lg overflow-hidden relative">
        {pdf ? (
          <iframe src={pdf.fileUrl} className="w-full h-full" title={pdf.title}></iframe>
        ) : (
          <p className="text-center mt-4">Loading PDF...</p>
        )}

        {/* AI Summarizer Button */}
        <button 
          onClick={fetchSummary} 
          className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-blue-500 text-white p-4 rounded-full shadow-lg hover:bg-blue-600 transition flex items-center justify-center"
          aria-label="AI Summarizer"
        >
          <FaRobot size={24} />
        </button>
      </div>

      {/* Summary Section */}
      {loadingSummary ? (
        <div className="absolute top-4 right-6 text-lg font-bold text-black bg-white p-2 rounded-lg shadow-md">
          Fetching summary...
        </div>
      ) : (
        summary && (
          <div className="absolute top-4 right-6 text-lg font-bold text-black bg-white p-2 rounded-lg shadow-md">
            Summary: {summary}
          </div>
        )
      )}

      {/* Close PDF Button */}
      <button 
        onClick={() => navigate(-1)}
        className="absolute bottom-4 right-6 bg-red-500 text-white px-4 py-2 rounded-lg"
      >
        Close PDF
      </button>
    </div>
  );
};

export default ViewPdf;
